import React from 'react'
import Layout from '../components/Layout'
import { RiStarSmileLine } from 'react-icons/ri'
import * as styles from '../styles/review.module.css'

export default function Review() {
    return (
        <Layout>
            <section className={styles.review}>
                <div className="u-center-text u-margin-bottom-big">
                    <h2 className="heading-secondary">
                        Customer Reviews and Comments
                    </h2>
                </div>
                <div className="row">
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            Apostille Express takes pride in the work we provide and we are very proud to show off with our reviews from customers as this puts an increase value to our service and reputation. All these comments are offered voluntarily from happy customers.
                        </p>
                        <p className="paragraph">
                            We update this section regularly and the reviews will change once this is done.
                        </p>
                    </div>
                    <div className="col-1-of-2">
                        <div className={styles.star}>
                            <RiStarSmileLine/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-1-of-2 u-center-text">
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            Thank you for your help - just wanted to commend you on your great service. <br/>
                            Thanks and regards
                        </h3>
                        <p className="paragraph">
                            Tarina - 15 May 2012
                        </p>
                    </div>
                    <div className="col-1-of-2 u-center-text">
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            We have found your company very efficient in the past at providing a service for us. Therefore I would like you to provide a quote [..] Look forward to your response and if you need any help with the attachment please ask. <br/>
                            Kind regards
                        
                        </h3>
                        <p className="paragraph">
                            Holly - 26 June 2012
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-1-of-2 u-center-text">
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            Thank you so much for all your help this morning!  I [...]really appreciate your quick level of service and will certainly be using your company in the future. <br/>
                            Many thanks,
                        </h3>
                        <p className="paragraph">
                            Emma - 22 June 2012
                        </p>
                    </div>
                    <div className="col-1-of-2 u-center-text">
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            Thank you again for your speed with this matter and I look forward to hearing from you tomorrow. <br/>
                            Kind regards
                        
                        </h3>
                        <p className="paragraph">
                            Lorna - 13 June 2012
                        </p>
                    </div>
                </div>
            </section>
        </Layout>
    )
}